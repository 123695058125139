import React, { useEffect } from 'react'

const Instaredirect = () => {
    useEffect(() => {
        window.location.href = 'https://www.instagram.com/thearadhyarana?igsh=MTB0bXdibTlqdG04bw=='
    }, [])


    return (
        <div>

        </div>
    )
}

export default Instaredirect
