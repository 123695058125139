import React, { useEffect } from 'react'

const DownloadContact5 = () => {
   const fetchDownload=()=>{
    var vCardData =
    "BEGIN:VCARD\r\n" +
    "VERSION:3.0\r\n" +
    "FN:Chandan"+
    "\r\n" +
    "N:MR FASHION - Chandan"+
    ";;;\r\n" +
    "TEL;TYPE=CELL:9884828782" +
    "\r\n"+
    "END:VCARD";

  var blob = new Blob([vCardData], { type: "text/vcard" });
  var downloadLink = document.createElement("a");
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = "MR FASHION - Chandan.vcf";
  downloadLink.style.display = "none";
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
   }

   useEffect(()=>{
    fetchDownload()
   })
  
    return (
    <div>
      
    </div>
  )
}

export default DownloadContact5